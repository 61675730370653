.reviewData {
    width: auto;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 18px;
    border-radius: 20px;
    padding: 20px;
}

.noReviewsFound-stats {
    display: none;
}

.reviewStatsLine {
    margin-bottom: 15px;
    width: max-content;
    font-weight: 400;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-right: 10px;
}

.reviewData p {
    user-select: none;
}

.propName {
    color: black;
    display: inline-block;
    padding: 10px 15px;
    font-weight: 500;
}

.reviewStatsText {
    display: inline-block;
    padding: 5px 15px;
    font-weight: 700;
}

.statsBoxTitle {
    font-weight: 500;
    font-size: 20px;
    color: black;
    margin: 10px 0 20px 10px;
}

@media (max-width: 1200px) {
    .reviewStatsLine {
        font-size: 14px;
    }
}


@media (max-width: 900px) and (min-width: 601px) {

    .right {
        width: 95%;
    }

    .reviewData {
        padding: 10px 15px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .statsBoxQualities {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
    }

    .reviewStatsText {
        padding: 5px 10px;
    }

    .statsBoxTitle {
        font-size: 17px;
    }
}

@media screen and (max-width: 600px) {
    .reviewData {
        padding: 10px 15px;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        display: flex;
        flex-direction: column;
    }

    .statsBoxQualities {
        flex-wrap: wrap;
        flex-direction: row;
    }

    .statsBoxQualities p {
        font-size: 12px;
    }

    .MuiTabs-flexContainer {
        margin-top: 5px;
    }

    .reviewStatsLine {
        gap: 5px;
        display: flex;
        align-items: center;
    }

    .statsBoxTitle {
        font-size: 17px;
    }
}