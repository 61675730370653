.nav {
	font-weight: 500;
	font-size: 1.5rem;
	color: white;
	user-select: none;
}

.nav-smalltext {
	display: none;
}

.nav:hover {
	color: var(--white-text-hover);
}

.navbar {
	display: flex;
	justify-content: space-between;
	min-height: 75px;
}

.homeNav {
	align-items: center;
	margin-right: 30px;
	margin-top: 30px;
	margin-left: 30px;
	color: white;
	display:flex;
	width: 100%;
	justify-content: flex-end;
}

.otherNav {
	width: 100%;
	margin-inline: 30px;
	margin-top: 30px;
}

.top-row-navbar {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.bottom-row-navbar {
	display: none;
}

.rightSide {
	display: flex;
	gap: 40px;
	align-items: center;
}

.addReviewText {
	user-select: none;
}

.mobile-add-review {
	display: none;
}

.navbar-search {
	flex: 1;
    margin-inline: 30px;
    z-index: 1;
	display: flex;
	align-items: center;
	background-color: white;
	height: 1.5rem;
	padding: 5px;
	border-radius: 15px;
	max-width: 900px;
}

.searchbar-divider {
	width: 1.5px;
	height: 25px;
	background-color: var(--navbar-divider);
}

@media screen and (max-width: 1200px) {
	.nav-smalltext {
		display: block;
	}

	.nav-fulltext {
		display: none;
	}
}

@media screen and (max-width: 1000px) {
	.navbar-search {
		margin-left: 20px;
	}
	
	.mobile-add-review {
		display: flex;
		align-items: center;
		margin-left: 0px;
	}

	.desktop-add-review {
		display: none;
	}

	.rightSide {
		gap: 10px;
	}

	.otherNav {
		margin-inline: 20px;
	}
}

@media screen and (max-width: 600px) {

	.top-row-navbar .navbar-search {
		display: none;
	}

	.bottom-row-navbar {
		display: block;
		margin-top: 10px;
	}

	.bottom-row-navbar .navbar-search {
		margin-left: 0px;
		margin-right: 10px;
	}

	.navbar {
		min-height: unset;
	}

	.homeNav {
		align-items: center;
		margin-right: 20px;
		margin-left: 20px;
		margin-top: 20px;
		color: white;
		display:flex;
		width: 100%;
		justify-content: flex-end;
	}

	.otherNav {
		margin-right: 15px;
		margin-left: 20px;
		margin-top: 20px;
		height: fit-content;
	}

	.nav {
		font-size: 1.15em;
	}
}