@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --grey-background: linear-gradient(rgb(23, 23, 23), rgb(24, 24, 24));
    --white-text-hover: rgb(208, 208, 208); /* Changed from 232, 232, 232 and 138, 138, 138*/
    --disclaimer-text: rgb(211, 211, 211);
    --review-text: rgb(115, 115, 115);
    --red-text: rgb(154, 12, 12);
    --orange-text: rgb(212, 74, 5);
    --yellow-text: rgb(206, 155, 0);
    --lightgreen-text: rgb(101, 154, 15);
    --green-text: rgb(30, 126, 30);
    --rating-button-selected: rgb(75, 75, 75);
    --scrollbar-color: rgb(215, 218, 226);
    --searchbar-item-hover: rgb(230, 230, 230);
    --white-button-hover:  rgb(238, 238, 238);
    --white-button-click:  rgb(222, 222, 222);
    --pink-highlight: rgb(140, 92, 92);
    --black-text-pink-highlight: rgb(246, 196, 197);
    --gold-star-color: rgb(255, 231, 97);

    --purple-button-background: rgb(47, 1, 49);
    --purple-button-background-hover: rgb(63, 0, 65);
    --purple-button-background-click: rgb(83, 1, 86);
    --purple-button-border: rgb(100, 0, 103);

    --gray-button-background: rgb(21, 21, 21);
    --gray-button-background-hover: rgb(45, 45, 45);
    --gray-button-background-selected: rgb(69, 69, 69);
    --gray-button-background-click: rgb(60, 60, 60);
    
    --white-button-background: rgb(64, 64, 64);
    --white-button-background-hover: rgb(77, 77, 77);
    --white-button-background-click: rgb(93, 93, 93);
    --mobile-margin-inline: 1rem;

    --navbar-divider: rgb(168, 168, 168);

    --black-text-hover: rgb(70, 70, 70);

    --almost-white-background: rgb(248, 248, 248);
}

body {
    background: rgb(0,0,0);
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100vh;
    /* background: linear-gradient(50deg, rgba(0,0,0,1) 0%, rgb(0, 3, 24) 65%, rgb(25, 0, 44) 100%); */
}

body::-webkit-scrollbar {
  width: 11px;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(199, 199, 199);
  border-radius: 6px;
  border: 1px solid black;
}

* {
    font-family: 'Inter', Roboto, sans-serif !important;
}

h1 {
    color: white;
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 20px;
}

p {
    color: white;
    margin: 1px;
}

.gray-box {
    background-image: var(--grey-background);
    padding-top: 5px;
	padding-bottom: 20px;
	padding-left: 25px;
	padding-right: 20px;
	border-radius: 20px;
    margin-bottom: 15px;
}

.white-box {
    background-color: var(--almost-white-background);
    box-shadow: 0px 0px 25px rgb(212, 212, 212);
}

.whiteButton {
    background-color: var(--white-button-background);
    color: white;
	font-size: 16px;
	font-weight: 500;
    border-radius: 20px;
	padding: 7px 15px;
    margin-left: 15px;
    border: 1.5px solid white; 
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.whiteButton:hover {
    background-color: var(--white-button-background-hover);
    cursor: pointer;
}

.whiteButton:active {
    background-color: var(--white-button-background-click);
}

.whiteButton-submit {
    margin-left: 0px;
}

.grayButton {
    background-color: var(--gray-button-background);
    color: white;
    border-radius: 20px;
	font-weight: 500;
	font-size: 18px;
	padding: 8px 15px;
    border: 1.5px solid white; 
    display: flex;
    align-items: center;
    justify-content: center;
}

.grayButton:hover {
    background-color: var(--gray-button-background-hover);
}

.grayButton:active {
    background-color: var(--gray-button-background-click);
}

input {
    border: none;
}

.purpleButton {
    background-color: var(--purple-button-background);
    color: white;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    height: 25px;
    padding: 5px 15px;
    margin-left: 15px;
    border: 1.5px solid var(--purple-button-border);
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    white-space: nowrap;
}

.purpleButton:hover {
    background-color: var(--purple-button-background-hover);
    cursor: pointer;
}

.purpleButton:active {
    background-color: var(--purple-button-background-click);
}

button {
    user-select: none;
    border: none;
}

button:hover {
    cursor: pointer;
}

.outlined-button {
    border: 1px solid white;
    background-color: black;
    color: white;
}

.outlined-button:hover {
    background-color: var(--rating-button-selected);
}

input:focus {
    outline: 1px solid white;
}

.standard-link {
    color: white;
    width: fit-content;
}

.standard-link:hover {
    color: var(--white-text-hover);
}

.black-background-input {
    color: white;
    background-color: black;
    border: 1px solid white;
    caret-color: white;
    font-size: 1rem;
    border-radius: 15px;
	padding-left: 10px;
	padding-block: 2px;
}

button:focus,
input:focus,
textarea:focus {
    outline: none;
}

.loading-div {
    position: relative;
}

.info-input {
    border-radius: 10px;
    padding-block: 5px;
    padding-left: 10px;
}

.copy-to-clipboard {
    font-size: 1rem;
}

.copied-text {
    font-size: 1rem;
}

.MuiTabs-indicator {
    height: 2.5px !important;
}

@media screen and (max-width: 1000px) {
    .copy-to-clipboard {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    body {
        min-height: -webkit-fill-available;
    }
    
    .whiteButton {
        font-size: 15px;
    }

    .grayButton {
        padding: 10px;
    }

    .copied-text {
        font-size: 0.7rem;
    }
}
