.DepartmentPage {
    margin-top: 2.5rem;
}

.DepartmentPage .desktopBottom,
.DepartmentPage .midSizeBottom,
.DepartmentPage .mobileBottom {
    background: var(--almost-white-background);
}

.DepartmentPage h1 {
    margin-top: 5px;
}

.departmentTopSection {
    width: fit-content;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: flex-start;
    font-weight: 500;
    border-radius: 20px;
    margin-left: 4rem;
    margin-right: 2rem;

    margin: 0px 2rem 2rem 4rem;
}

.departmentTitleName {
    width: fit-content;
    font-size: 45px;
}

.link {
    font-size: 16px;
    font-weight: 400;
    color: white;
    margin: 5px;
    margin-left: 0px;
    width: fit-content;
    max-width: 50ch;
}


.department-title-line {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.departmentOverallStats {
    border-radius: 20px;
    font-size: 15px;
    display: flex;
    gap: 20px;
    font-weight: 400;
    margin-top: 10px;
}

.professorsColumn,
.coursesColumn {
    border-radius: 20px;
    padding: 5px 15px;
    display: flex;
    flex-direction: column;
}

.professorsColumn .link, .coursesColumn .link {
    display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 10px;
	background-color: rgb(230,230,230);
	border-radius: 8px;
    width: 100%;
}

.professorsColumn .link:hover, .coursesColumn .link:hover {
	background-color: rgb(225,225,225);
}

.statsListItem p {
	color: black;
	font-size: 16px;
}

.professorsColumn {
    max-width: 20%;
}

.department-column-title {
    margin-bottom: 10px;
    color: black;
}

.professors,
.courses {
    display: flex;
    flex-direction: column;
}

.professors > *,
.courses > * {
    color: black;
}

.professors {
    padding-right: 20px;
}

.courses {
    padding-right: 10px;
}


.desktopBottom {
    display: flex;
    gap: 20px;
    padding-left: 3rem;
    padding-right: 2rem;
    padding-block: 2rem;
    justify-content: space-around;
}

.abbrDept {
    color: black;
    background-color: white;
    font-weight: 500;
    font-size: 2rem;
    border: 1px solid white;
    border-radius: 20px;
    padding: 10px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: 20px;
    text-align: center;
}

.DepartmentPage .reviewData {
    font-size: 18px !important;
    margin-left: 30px;
}

.DepartmentPage ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
  
.DepartmentPage ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--scrollbar-color);
}

.mobileBottom {
    display: none;
}

.midSizeBottom {
    display: none;
}

.DepartmentPage .abbr {
    width: fit-content;
    margin-bottom: 0px;
}

.current-filter-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.current-filter-buttons button {
    color: black;
    background: none;
    font-size: 15px;
    padding: 0px
}

.current-filter-buttons button:not(.filter-selected):hover {
    color: var(--black-text-hover);
}

.current-filter-buttons .filter-selected {
    font-weight: 600;
}

.course-line {
    display: flex;
    gap: 15px;
}

.bold-abbr {
    font-weight: 600;
    border: 1.5px solid black;
    border-radius: 7px;
    padding: 5px 10px 5px 10px;
    max-width: 100px !important;
    min-width: max-content;
}

.course-line {
    display: flex;
    align-items: center;
}

@media (max-width: 1050px) and (min-width: 900px) {
    .desktopBottom {
        display: none;
    }

    .midSizeBottom {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        padding: 2rem 3rem;
        max-height: 650px;
    }

    .mobileBottom .MuiTabs-root,
    .midSizeBottom .MuiTabs-root {
        margin-bottom: 10px;
        min-height: unset;
    }

    .mobileBottom .MuiTabs-flexContainer,
    .midSizeBottom .MuiTabs-flexContainer {
        gap: 10px;
    }

    .midSizeBottom .MuiTabs-flexContainer {
        justify-content: center;
    }

    .mobileBottom .MuiTab-root,
    .midSizeBottom .MuiTab-root {
        min-height: unset;
        min-width: unset;
        width: fit-content;
        max-width: unset;
        color: black !important;
        font-size: 16px !important;
        font-weight: 600 !important;
    }


    .midSizeBottom .professorsColumn,
    .midSizeBottom .coursesColumn {
        width: 90%;
        background: none;
        box-shadow: none;
        padding: 0px;
        max-width: 90%;
    }

    .midSizeBottom .department-tab-title {
        margin-top: 10px;
    }

    .midSizeBottom-left {
        width: 50%;
        padding-left: 30px;
        padding-top: 5px;
        padding-bottom: 20px;
        border-radius: 20px;
        height: 650px;
        overflow: scroll;
    }

    .professors, .courses {
        max-height: none;
        align-items: center;
        display: flex; 
        flex-wrap: wrap;
        flex-direction: row;
    }

    .professorsColumn .link, .coursesColumn .link {
        min-width: 100%;
    }
}

@media (max-width: 900px) {

    .DepartmentPage .reviewData { 
        width: 80%;
        margin-left: 0;
    }

    .desktopBottom,
    .midSizeBottom {
        display: none;
    }

    .DepartmentPage .link {
        font-size: 15px;
    }

    .departmentTitleName {
        font-size: 35px;
    }

    .mobileBottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 0rem 2rem 0rem;
        gap: 30px;
        width: 100%;
        margin-top: auto;
        height: 650px;
    }

    .coursesColumn,
    .professorsColumn {
        padding: 0.75rem 1.5rem;
        max-width: 90%;
        width: 90%;
        height: 650px;
        overflow: scroll;
    }

    .professors {
        max-height: none;
        padding: 0px;
        align-items: center;
        display: flex; 
        flex-wrap: wrap;
        flex-direction: row;
    }

    .professors,
    .courses {
        max-height: none;
        padding: 0px;
        display: flex; 
        width: 100%;
    }

    .DepartmentPage {
        margin-top: 20px;
    }
    
    .departmentOverallStats {
        padding: 5px 0px;
        gap: 5px;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 14px;
        margin-top: 0px;
    }

    .departmentTopSection {
        flex-direction: column;
        margin-inline: 1rem;
        margin-bottom: 35px;
        gap: 1rem;
        align-items: normal;
        font-size: 25px;
        padding: 0.5rem;
        padding-bottom: 0;
        border-radius: 20px;
    }

    .department-tab-title {
        margin-block: 10px;
        font-size: 20px;
    }

    .professorsColumn .link, .coursesColumn .link {
        width: auto;
    }
  }