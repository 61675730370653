.Review {
    border-radius: 10px;
    font-size: 18px;
    font-weight: 350;
    color:white;
    margin-block: 15px;
    display: flex;
    flex-direction: column;
    padding: 10px 25px;
}

.user-reviews .Review {
    box-shadow: none;
    margin-inline: 20px;
}

.topRows {
    display: flex;
    justify-content: space-between;
}

.profName {
    font-size: 17px;
    font-weight: 400;
    color: black;
    margin-top: 10px;
}

.profName:hover {
    text-decoration: underline;
}

.Number {
    font-size: 22px;
    font-weight: 600;
    border: 1.5px solid black;
    border-radius: 30px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: black;
}

.reviewText {
    color: black;
    font-size: 1rem;
    line-height: 1.6rem;
    padding-bottom: 10px;
}

.empty-review {
    margin-bottom: 0px;
    height: 0px;
}

.date {
    display: flex;
    justify-content: flex-end;
    font-weight: 400;
    font-size: 14px;
    align-items: flex-end;
    color: rgb(130, 130, 130) !important;
}

.dateTaken {
    font-size: 10px;
    font-weight: 350;
    display: flex;
    padding-left: 5px;
    color:rgb(121, 121, 121);
}

.semesterTaken {
    font-size: 12px;
    color: rgb(121, 121, 121);
    margin-top: 5px;
}

.revTable {
    display: flex;
    margin-top: 20px;
    margin-bottom: 15px;
    flex-wrap: wrap;
}

.leftCol {
    font-weight: 700;
    font-size: 13px;
}

.rightCol {
    font-weight: 600;
}

.rightCol {
    font-weight: 400;
    font-size: 13px;
}

.courseLink {
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.courseLink:hover {
    text-decoration: underline;
}

.smallTitle {
    font-size: 14px;
    font-weight: 500;
    color: black;
}

.quality {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 8px 12px;
    border-radius: 10px;
    text-align: center;
}

@media (max-width: 600px) {
    .Review {
        border-radius: 20px;
        font-size: 17px;
        padding-inline: 22px;
        padding-block: 15px;
        margin: 0px;
        margin-top: 10px;
        margin-right: 0;
    }

    .user-reviews .Review {
        margin-inline: 15px;
        padding-inline: 15px;
    }

    .profName {
        font-size: 15px;
    }

    .courseLink {
        font-size: 17px;
    }
    
    .Number {
        font-size: 1.75rem;
        border-radius: 25px;
    }

    .revTable {
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .reviewText {
        font-size: 14px;
    }
}

@media screen and (max-width: 500px) {
    .revTable {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}