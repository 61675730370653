.newProfessorForm {
	color: white;
	background-image: var(--grey-background);
	padding-top: 5px;
	padding-bottom: 10px;
	padding-left: 30px;
	border-radius: 40px;
	margin-bottom: 20px;
	margin-inline: 5rem;
	margin-top: 2rem;
	max-width: 500px;
}
  
.newProfessorForm input:not(.whiteButton) {
	display: block;
	margin-bottom: 10px;
	border-radius: 10px;
	padding: 5px 10px;
	font-size: 16px;
}

.professorDisclaimer {
	color: var(--disclaimer-text);
	margin-right: 10px;
	margin-bottom: 30px;
}

.newProfessorForm button {
	border-radius: 50%;
	width: 30px;
	height: 30px;
	border: 2px solid white;
	background-color: black;
	color: white;
	margin: 5px;
	font-size: 16px;
	cursor: pointer;
}

button.selected {
	color: blue;
}

.professorSubmit {
	margin-top: 20px;
	background-color: white;
	border-radius: 20px;
	color: black;
	font-weight: 600;
	border: 1px solid white;
	font-size: 18px;
}

.firstNameInput {
	max-width: 50%;
	font-size: 17px;
}

.lastNameInput {
	max-width: 50%;
	min-width: 41px;
	font-size: 17px;
}

@media screen and (max-width: 800px) {

	.newProfessorForm {
		margin-inline: 3rem;
	}
	
}

@media screen and (max-width: 600px) {
	.newProfessorForm {
		margin-inline: var(--mobile-margin-inline);
		margin-top: 2rem;
		padding-inline: 20px;
		width: calc(100% - 40px - 2 * var(--mobile-margin-inline));
		max-width: 100%;
	}
}