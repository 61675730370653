.searchBar {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.searchInput {
    width: 100%;
    position: relative;
}

.searchInput-input {
    display: block;
    background-color: white;
    border: 0;
    border-radius: 20px;
    font-size: 18px;
    padding: 15px;
    height: 2rem;
    width: 70%;
    font-weight: 500;
}

.searchInput input::selection {
    background-color: var(--black-text-pink-highlight) !important;
}

.searchIcon {
    height: 60px;
    width: 50px;
    background-color: white;
    display: grid;
    place-items: center;
}

.searchResults {
    margin-top: 5px;
    width: 70%;
    background-color: white;
    overflow-x:hidden;
    border-radius: 20px;
    max-height: 13rem;
    position: absolute;
    top: 100%;
}

.searchResults::-webkit-scrollbar {
    display:none;
}

.searchResults .searchItem:hover {
    background-color: var( --searchbar-item-hover);
}

.searchResults .no-results:hover {
    background-color: white;
}

a {
    text-decoration: none;
    display: block;
}

.add-line {
    color: white;
    display: flex;
    margin-left: 1rem;
    align-items: center;
    margin-top: 15px;
    position: absolute;
    top: 100%;
    z-index: 0;
}

.addLink:hover {
    background-color: var(--white-button-hover);
}

.addLink:active {
    background-color: var(--white-button-click);
}

/* Navbar search bar */

.navbar-search-bar {
    flex: 1;
}

.navbar-search-bar .add-line {
    display: none;
}

.searchBar.navbar-search-bar {
    margin-top: 0px;
}

.navbar-search-bar .searchInput-input {
    width: 100%;
    padding: 5px;
    padding-left: 15px;
    font-size: 16px;
    height: 1.5rem;
    border-radius: 15px;
}

.navbar-search-bar .searchResults {
    width: calc(100% + 15px);
}

.searchResults .searchItem {
    height: 50px;
    color: black;
    padding-left: 1rem;
    display: flex;
    align-items: center;
}

.navbar-search-bar .searchResults .searchItem {
    width: calc(100% - 1.5rem);
    height: unset;
    min-height: 50px;
}

@media screen and (max-width: 800px) {
    .searchResults {
        padding-block: 5px;
        left: 0;
        right: 0;
        margin-top: 5px;
        border-radius: 20px;
        max-height: calc(100vh - 550px);

        display: flex;
        flex-direction: column;

        z-index: 1;
    }

    .searchResults .searchItem {
        height: auto;
        min-height: auto !important;
        padding-block: 8px;
    }

    .navbar-search-bar .searchResults .searchItem {
        height: auto;
        min-height: auto !important;
    }
}

@media screen and (max-width: 600px) {
    .searchBar {
        margin-top: 1rem;
        width: 100%;
    }

    .searchResults {
        width: auto;
    }

    .HomePage .searchInput {
        width: calc(100vh - 1.5rem);
    }

    .searchInput-input {
        border-radius: 20px;
        font-size: 16px;
        padding: 15px;
        height: 1rem;
        color:black;
        margin-inline: auto;
        width: 85%;
    }

    .add-line {
        width: auto;
        margin-inline: auto;
        text-align: center;
        left: 0;
        right: 0;
        margin-inline: auto;
        margin-top: 20px;
        flex-direction: column;
        gap: 10px;
    }

    .addLink {
        font-size: .9rem;
        padding: 5px;
        width: auto;
        height: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }
}