.contactUs {
	margin-top: 2rem;
}

.contactUsForm {
	max-width: 750px;
	color: white;
	margin-inline: 5rem;
	padding: 15px 40px;
}

.contactus-title {
	margin-top: 10px;
}

.header {
	margin-bottom: 20px;
}

.disclaimer {
	line-height: 1.5;
}

.contactUsQuestions > * {
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
}

.emailInput {
	max-width: 35%;
	min-width: 200px;
}

.questionTitleContact {
	margin-top: 20px;
	margin-bottom: 5px;
}

.contactUsForm .comment {
	margin-top: 30px;
}

@media screen and (max-width: 600px) {
	.contactUs {
		margin-inline: var(--mobile-margin-inline);
	}

	.contactUsForm {
		padding: 20px;
		margin-inline: 0px;
	}

	.contactus-title {
		margin-top: 0px;
	}

	.bottomLine {
		margin-top: 15px;
	}
}