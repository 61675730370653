.HomePage {
  text-align: left;
  margin-left: 7rem;
}

.HomePage-header {
  font-size: 4rem;
  color: white;
  margin-top: 3rem;
  font-weight: 800;
  user-select: none;
  margin-bottom: 30px;
}

.search-tab {
  color: white !important;
  width: auto;
  max-width: 33% !important;
}


@media (max-width: 600px) {
  .HomePage {
    margin-inline: var(--mobile-margin-inline);
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .HomePage-header {
    font-size: 40px;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    font-weight: 800;
    text-align: center;
  }

  .searchTabs {
    padding-inline: 1rem;
    margin-top: 30px;
    font-size: 8px;
    margin-top: 2rem;
  }

  .HomePage .MuiTabs-root {
    min-height: unset !important;
  }

  .HomePage .MuiTab-root {
    font-size: 12px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    max-width: unset !important;
    min-width: 100px !important;
    min-height: unset !important;
  }

  .HomePage .MuiTabs-flexContainer {
    display: flex;
    justify-content: space-between;
  }
}
