.footer{
	display: flex;
	justify-content: right;
	margin-top: auto;
	padding-right: 1rem;
	background-color: rgb(17, 17, 17);
	
}

.light-footer {
	background-color: rgb(233, 233, 233);
}

.copyRightText {
	color: rgb(168, 168, 168);
	font-size: 15px;
	text-align: center;
	border-radius: 25px;
	width: fit-content;
	margin-block: 15px;
	padding: 5px 10px 5px 0;
	font-weight: 600;
}

.light-footer .copyRightText {
	color: rgb(72, 72, 72);
	background-image: none;
	margin: 0px;
	padding-block: 1rem;
}

@media screen and (max-width: 600px) {
	.footer {
		padding-right: 0;
		justify-content: center;
	}

	.copyRightText {
		font-size: 12px;
		padding-right: 0px;
		margin-block: 8px;
	}

	.light-footer .copyRightText {
		margin-block: 8px;
		padding: 0px;
	}

}