.title404 {
	font-size: 1.5rem;
}

.notFound {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: white;
    padding: 0px;
    margin-top: 8rem;
}