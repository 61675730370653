.statsListSortLine {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 20px;
	margin-top: 5px;
	margin-left: 1rem;
}

.statsListSortText {
	color: black;
	font-size: 15px;
}

.statsListItems {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 20px 15px;
}

.statsListItem {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 10px;
	background-color: rgb(230,230,230);
	border-radius: 5px;
}

.cpd-list .statsListItem:hover {
	background-color: rgb(225,225,225);
}

.statsListItem p {
	color: black;
	font-size: 16px;
}

.statsListNumber {
	width: 20px;
	margin-right: 10px;
	text-align: right;
}

.statsListItemLeft {
	display: flex;
	align-items: center;
	gap: 5px;
}

.statsListItemRight {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 0.5rem;
}

.statListValue {
	font-weight: 400;
}

.statListReviewCount {
	color: rgb(132, 132, 132);
	font-size: 12px !important;
	min-width: 73px;
	text-align: right;
}

.statListReviewCountLarge {
	font-weight: 600;
}

.MuiTableSortLabel {
	font-size: 15px;
}

.sort-label {
	padding-bottom: 0px !important;
	margin-left: 10px !important;
}

.statsList {
	max-width: 1000px;
}

@media screen and (max-width: 1000px) {
	.statsListKey {
		white-space: normal;
	}
}

@media screen and (max-width: 600px) {
	.statsListSortLine {
		gap: 5px;
		margin-bottom: 25px;
		margin-left: 0px;
	}

	.statsListSortText {
		font-size: 14px;
	}

	.statsListItems {
		padding-inline: 5px;
	}

	.statsListItem {
		font-size: 15px;
		gap: 10px;
	}

	.statsListItem p {
		font-size: 15px;
	}

	.statsListNumber {
		width: unset;
		text-align: left;
		margin-right: 1rem;
	}

	.statsListItemLeft {
		width: 55%;
		gap: 0px;
	}

	.statsListItemRight {
		gap: 5px;
	}

	.statListReviewCountLarge {
		font-size: 13px;
	}

	.sort-label {
		margin-left: 5px !important;
		font-size: 13px !important;
	}
}