.MuiMenuItem-root {
	font-size: 20px;
	font-weight: 450;
}

.hamburgerIcon {
	font-size: 25px;
	color: white !important;
	transition: none;
	outline: 0;
}

.hamburgerIcon:hover,
.hamburgerIcon:focus {
	color: var(--white-text-hover) !important;
}

.faIcon{
	font-size: 15px !important;
	color: white !important;
}

#bars {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.hamburger-bars:hover {
	cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
	transition: 0.3s ease-in-out;
}

.closed .bar1 {
	transform: translate(0px, 10px) rotate(-45deg);
}

.closed .bar2 {
	transform: rotate(45deg);
}

.closed .bar3 {
	transform: translate(0px, -10px) rotate(45deg);
}

.bar {
	height: 2px;
	width: 30px;
	background-color: white;
	margin: 0px;
}

@media (max-width: 600px) {
	.faIcon {
		font-size: 18px !important;
	}

	.hamburgerMenu .MuiMenuItem-root {
		font-size: 16px;
		font-weight: 425;
		padding-left: 8px;
		padding-right: 8px !important;
		min-height: unset;
	}

	.hamburgerMenu .MuiList-root {
		padding-block: 2px !important;
	}

	.hamburgerIcon {
		font-size: 20px;
	}

}