.statsPage {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 2.5rem;
}

.statsPage-content {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: var(--almost-white-background);
	gap: 2rem;
}

.top-section p {
	color: white;
}

.statspage-faicon {
	color: white;
	font-size: 20px;
}

.stats-list-title {
	margin-top: 10px;
	margin-bottom: 5px;
	text-align: center;
}

.tabDiv {
	display: flex;
	flex-direction: row;
	gap: 15px;
}

.not-clicked,
.clicked {
	border: 3px solid black;
	padding: 5px 15px;
	border-radius: 20px;
	font-weight: 500;
	transition: ease-in-out 0.3s;
}

.not-clicked {
	color: black;
}

.clicked {
	background-color: black;
	color: white;
}

.tabDiv p:hover {
	cursor: pointer;
	background-color: black;
	color: white;
}

.filters-line {
	display: flex;
	gap: 20px;
	align-items: center;
	margin-left: 1rem;
	margin-bottom: 1rem;
}

.statsPage-content h1 {
	color: black;
	font-weight: 600;
	font-size: 1.8rem;
	flex-wrap: nowrap;
}

.top-section {
	margin-left: 4rem;
	font-size: 20px;
	margin-bottom: 40px;
}

.titleStats {
	text-align: left;
	font-size: 45px;
	margin-bottom: 10px;
	margin-top: 0px;
}

.overallStats {
	display: flex;
	gap: 20px;
	font-size: 15px;
}

.overallStats-item {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.statsLists {
	margin-top: 15px;
	margin-left: 3rem;
	margin-right: 2rem;
}

@media screen and (max-width: 900px) {
	.statsPage {
		margin-top: 10px;
		margin-inline: 0;
		gap: 10px;
	}

	.top-section {
		margin-inline: 1rem;
	}

	.titleStats {
		margin-block: 15px;
		font-size: 30px;
	}

	.overallStats {
		font-size: 15px;
		justify-content: space-around;
		gap: 10px;
	}

	.overallStats-item {
		text-align: center;
		font-size: 13px;
		gap: 5px;
	}

	.statspage-faicon {
		font-size: 15px;
	}

	.statsPage-content {
		flex-direction: column;
	}

	.stats-list-title {
		font-size: 24px;
		margin-top: 5px;
	}

	.statsLists {
		width: calc(100% - 30px);
		padding: 15px;
		border-radius: 20px;
		margin-inline: 0rem;
	}
}

@media screen and (max-width: 600px) {
	.top-section {
		margin-bottom: 1.5rem;
	}

	.statsLists {
		margin-top: 0px;
	}
	
	.filters-line {
		flex-direction: column;
		align-items: flex-start;
		gap: 0.75rem;
		margin-left: 0px;
	}

	.tabDiv {
		gap: 0.5rem;
	}

	.not-clicked,
	.clicked {
		font-size: 14px;
	}

	.statsPage .MuiInputBase-root {
		min-width: 180px !important;
	}
}