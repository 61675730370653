.profilePage {
	display: flex;
	gap: 40px;
	margin-top: 40px;
	margin-left: 3rem;
}

.profilePageInfo {
	height: fit-content;
	min-width: 250px;
	position: sticky;
	top: 40px;
}

.edit-save-button {
	padding: 8px 15px;
	height: unset;
	margin-left: 0px;
	font-size: 18px;
}

.starred-courses {
	position: sticky;
	top: 335px;
}

.starred-courses-list {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.starred-course-line {
	display: flex;
	align-items: center;
	gap: 10px;
}

.profilePageInfo .detail {
	font-size: 16px;
}

.user-reviews {
	display: flex;
	flex-direction: column;
	align-items: center;
    background-image: var(--grey-background);
    border-radius: 20px;
	padding-block: 5px;
	margin-right: 1rem;
	width: 100%;
	height: fit-content;
	padding-bottom: 20px;
	margin-bottom: 15px;
}

.user-reviews .profName:hover,
.user-reviews .courseLink:hover {
	text-decoration: underline;
	cursor: pointer;
}

.your-reviews-title {
	text-align: center;
	margin-bottom: 5px;
	margin-top: 10px;
	font-weight: 525;
}

.no-reviews {
	margin-top: 2rem;
	margin-inline: auto;
}

.profilePageTitle {
	margin-block: 0px;
	font-weight: 500;
}

.lines {
	display: flex;
	font-size: 20px;
	flex-direction: column;
	color: white;
	font-weight: 700;
	gap: 10px;
}

.detail {
	font-weight: 400;
	color: white;
}

.detail-link:hover {
	color: var(--white-text-hover);
	cursor: pointer;
}

.topLineProfile {
	margin-bottom: 15px;
	margin-top: 10px;
	display: flex;
	align-items: center;
	gap: 20px;
}

.line {
	display: flex;
	align-items: center;
	gap: 10px;
	min-height: 30px;
}

.lineTitle {
	white-space: nowrap;
	min-width: 75px;
	font-size: 16px;
}

.profilePageInfo .MuiInputBase-input {
	padding: 0px !important; 
}

.grad-year-display-text {
	font-size: 16px;
	font-weight: 400;
}

.grad-year-input {
	border-radius: 10px;
	max-width: unset;
	min-width: unset;
	width: 60px;
	height: 24px;
	background-color: var(--grey-background);
}

@media (max-width: 900px) {
	.profilePage {
		flex-direction: column;
		margin-top: 1rem;
		margin-inline: var(--mobile-margin-inline);
		gap: 20px;
	}

	.profilePage .left {
		display: flex;
		gap: 2rem;
		margin-top: 20px;
	}

	.profilePageInfo,
	.starred-courses {
		flex: 1;
	}

	.profilePageInfo {
		width: auto;
		min-width: unset;
		max-width: 350px;
		position: relative;
		top: 0px;
	}

	.edit-save-button {
		font-size: 16px;
		padding: 6px 12px;
	}

	.starred-courses {
		max-width: 350px;
		position: relative;
		top: 0px;
	}

	.your-reviews-title {
		margin-top: 5px;
		font-size: 24px;
	}

	.user-reviews {
		margin-right: 0;
	}

	.profilePageTitle,
	.starred-courses-title {
		font-size: 24px;
	}

	.topLineProfile {
		margin-bottom: 10px;
	}

	.lines {
		font-size: 18px;
	}

	.no-reviews {
		margin-top: 2rem;
		margin-bottom: 6rem;
	}
}

@media screen and (max-width: 800px) {
	.profilePage .left {
		gap: 1rem;
	}
}

@media screen and (max-width: 700px) {
	.profilePage .left {
		display: block;
		margin-inline: auto;
	}
}

@media screen and (max-width: 600px) {
	.line {
		min-height: 38px;
	}
}