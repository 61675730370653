.existing-description {
    margin-block: 1rem;
}

.existing-description p {
    line-height: 20px;
    color:rgb(212, 212, 212);
}

.title-overall-stats {
    font-weight: 700;
    font-size: 14px;
    display: flex;
    gap: 15px;
    margin-top: 10px;
}

.title-overall-stat-line {
    padding: 10px 15px;
    width: fit-content;
    border-radius: 10px;
}

.review-page-title {
    color: white;
    font-size: 3em;
    display: flex;
    gap: 10px;
    padding-bottom: 2rem;
    padding-top: 0px;
    border-radius: 20px;
    flex-direction: column;
    border-radius: 20px;
    padding-inline: 4rem; 
}

.review-page-title .title-no-description {
    gap: 0px;
}

.review-page-title .professor-title-top {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.courseTitle {
    font-weight: 500;
    font-size: 45px;
}

.description {
    width: 80%;
    font-size: 15px;
}

.review-page-title .title-top-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
}

.review-page-title .title-bottom-row {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.no-description {
    margin-top: 0px;
    font-size: 0px;
}

.abbreviations {
    display: flex;
    gap: 10px;
}

.abbr {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-weight: 600;
    font-size: 20px;
    border: 2px solid rgba(255, 255, 255, 0.90);
    color: rgba(255, 255, 255, 0.84);
    background-color: rgba(255, 255, 255, 0.11);
    border-radius: 10px;
    padding: 10px 15px;
    gap: 8px;
    margin-bottom: 10px;
    letter-spacing: 1px;
}

.department-abbr:hover {
    background-color: rgba(255, 255, 255, 0.18);
}

.review-page-title-loading {
    margin-bottom: 2rem;
    max-width: 1200px;
}

/* Professor-page specific stuff*/
.departmentList .grayButton {
    font-size: 40px;
}

.departmentList {
    display: flex;
    gap: 10px;
}

.star-icon {
    color: var(--gold-star-color);
    width: 22px;
    height: 22px;
    overflow-clip-margin: initial;
    overflow: visible;
}

.star-icon:hover {
    cursor: pointer;
}

@media screen and (max-width: 1100px) {
    .review-page-title {
        padding: 1rem 3rem;
    }
}

@media screen and (max-width: 800px) {

    .description {
        width: unset;
        margin: 0px;
    }

    .review-page-title {
        max-width: unset;
        display: flex;
        justify-content: left;
        font-size: 1.75em;
        gap: 10px;
        padding: 2rem;
        align-items: flex-start;
        max-width: unset;
    }

    .review-page-title .title-bottom-row {
        display: flex;
        align-items: center;
    }

    .courseTitle {
        font-size: 25px;
    }

    /* Professor page-specific stuff */
    .departmentList {
        gap: 7px;
    }

    .departmentList .grayButton {
        font-size: 20px;
        padding: 5px 10px;
    }

    .review-page-title .title-top-row {
        gap: 10px;
    }
}
