.buttonRow {
	display: flex;
	gap: 5px;
}

.buttonDiv {
	width: 150px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.B {
	border-radius: 20px;
	width: 140px;
	height: 35px;
	border: 1px solid white;
	background-color: var(--gray-button-background);
	color: white;
	font-size: 14px;
	cursor: pointer;
	margin-inline: auto;
}
  
.selectedB {
	color: white;
	background-color: var(--gray-button-background-selected);
	border: 1.5px solid white;
	font-weight: 600;
	width: 140px;
	height: 35px;
	border-radius: 20px;
	font-size: 14px;
	cursor: pointer;
	margin-inline: auto;
}

.B:hover {
	background-color: var(--gray-button-background-hover);
	border: 1px solid white;
}

/* Colors */
.b1:hover {
	background-color: rgba(154, 12, 12, 0.2);
	border: 1.5px solid var(--red-text);
}

.sb1 {
	background-color: rgba(154, 12, 12, 0.4);
	border: 1.5px solid var(--red-text);
	color: rgb(235, 214, 214);
}

.b2:hover {
	background-color: rgba(212, 74, 5, 0.2);
	border: 1.5px solid var(--orange-text);
}

.sb2 {
	background-color: rgba(212, 74, 5, 0.4);
	border: 1.5px solid var(--orange-text);
	color: rgb(245, 232, 226);
}

.b3:hover {
	background-color: rgba(206, 155, 0, 0.2);
	border: 1.5px solid var(--yellow-text);
}

.sb3 {
	background-color: rgba(206, 155, 0, 0.4);
	border: 1.5px solid var(--yellow-text);
	color: rgb(240, 235, 219);
}

.b4:hover {
	background-color: rgba(113, 128, 0, 0.2);
	border: 1.5px solid var(--lightgreen-text);
}

.sb4 {
	background-color: rgba(113, 128, 0, 0.4);
	border: 1.5px solid var(--lightgreen-text);
	color: rgb(234, 238, 209);
}

.b5:hover {
	background-color: rgba(30, 126, 30, 0.2);
	border: 1.5px solid var(--green-text);
}

.sb5 {
	background-color: rgba(30, 126, 30, 0.4);
	border: 1.5px solid var(--green-text);
	color: rgb(195, 226, 195);
}

@media (max-width: 600px) {

	.buttonDiv {
		width: auto;
		margin: 3px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	} 

	.buttonRow {
		gap: 1px;
		display: flex;
		flex-wrap: wrap;
	}

	.B,
	.selectedB {
		width: auto;
		padding: 10px;
		font-size: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.B {
		font-weight: 400;
		cursor: pointer;
	}
	  
	.selectedB {
		font-weight: 500;
	}

	
}