.ReviewList {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
}

.reviewList-bottom {
    display: flex;
    gap: 30px;
    background-color: var(--almost-white-background);
    padding-block: 2rem;
    padding-left: 4rem;
    padding-right: 1.5rem;
}

.reviewList-bottom .left {
    flex: 1;
}

.right {
    position: sticky;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    top: 40px;
    gap: 20px;
}

.right .reviewData {
    min-width: 30%;
}

.profTitle {
    color: white;
    font-size: 1.5em;
    margin-top: 10px;
    margin-left: 3.25rem;
    margin-bottom: 2rem;
    font-weight: 200;
} 

@media screen and (max-width: 1100px) {
    .reviewList-bottom {
        padding-left: 3rem;
        padding-right: 2rem;
    }
}

@media screen and (max-width: 900px) {

    .ReviewList {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 30px;
    }

    .reviewList-bottom {
        display: flex;
        flex-direction: column-reverse;
        margin-right: 0px;
        margin-top: 20px;
        padding-inline: 1rem;
        padding-bottom: 1rem;
        align-items: center;
    }

    .right {
        position: relative;
        top: 0;
        justify-content: center;
        width: -content;
    }
    
    .copy-to-clipboard-review-page {
        display: none;
    }

    .reviewList-bottom .left {
       max-width: 95%;
    }

    .departmentList {
        gap: 7px;
    }
}

@media screen and (max-width: 600px) {
    .reviewList-bottom {
        padding-inline: var(--mobile-margin-inline);
        align-items: center;
    }
}