.signIn {
	margin-left: 6rem;
	display: flex;
	justify-content: space-between;
	margin-right: 6rem;
}

.signInRightSide img {
	width: 90%;
	padding-left: 2rem;
	min-width: 500px;
	max-width: 650px;
}

.bottomLine {
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 10px;
	padding-top: 40px;
}

.HomePage-header {
	font-size: clamp(1.75rem, .6692rem + 5vw, 4rem);
	margin-top: 3rem;
	color: white;
	font-weight: 800;
	user-select: none;
	margin-bottom: 30px;
  }
  

@media (max-width: 850px) {
	.signIn {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-left: 0;
		align-items: center;
		margin-bottom: 1rem;
	}

	.signInRightSide img {
		width: 80%;
		padding-left: 0;
		min-width: 0px;
		max-height: none;
	}
	
	.signInRightSide {
		display: flex;
		justify-content: center;
		width: 100%;
		padding-top: 2rem;
	}

	.signInLeftSide {
		width: 85%;
		margin-left: 2rem;
	}

	.signInRightSide {
		margin-bottom: 10px;
	}

	.HomePage-header {
		color: white;
		padding-top: 3rem;
		font-weight: 800;
		user-select: none;
		margin-bottom: 30px;
		text-align: left;
		margin-top: 0;
	}

	.bottomLine {
		padding-top: 20px;
	}
}

@media (min-width: 600px) and (max-width: 850px) {
	.HomePage-header {
		padding-top: 0;
	}
}