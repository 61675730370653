.aboutPage {
	max-width: 750px;
	margin-inline: 5rem;
	margin-top: 2rem;
	padding: 15px 40px;
}

.about-page-title {
	margin-top: 10px;
}

.aboutText {
	max-width: 800px;
	color: white;
	margin-bottom: 1rem;
}

@media (max-width: 600px) {
	.aboutPage {
		margin-inline: var(--mobile-margin-inline);
		padding: 20px;
	}

	.aboutText {
		width: 100%;
		text-align: left;
	}

	.about-page-title {
		margin-bottom: 15px;
		margin-top: 0px;
	}
}