.newClassForm {
	color: white;
	background-image: var(--grey-background);
	padding-top: 5px;
	padding-bottom: 10px;
	padding-left: 30px;
	border-radius: 40px;
	margin-bottom: 20px;
	margin-inline: 5rem;
	margin-top: 2rem;
	max-width: 500px;
}

.courseDisclaimer {
	color: var(--disclaimer-text);
	margin-right: 10px;
	margin-bottom: 20px;
}
  
.newClassForm input:not(.whiteButton) {
	display: block;
	margin-bottom: 10px;
	border-radius: 10px;
	font-size: 16px;
	padding-block: 5px;
	padding-left: 10px;
}

.newClassForm button {
	border-radius: 50%;
	width: 30px;
	height: 30px;
	border: 2px solid white;
	background-color: black;
	color: white;
	margin: 5px;
	font-size: 16px;
	cursor: pointer;
}
  
button.selected {
	color: blue;
}

.createCourseQuestion {
	display: flex;
	flex-direction: column;
	margin-right: 30px;
	margin-bottom: 10px;
}

.createCourseQuestionTitle {
	font-weight: 700;
	margin-bottom: 7px;
}

.newClassForm .commentBox {
	width: 80%;
}

.newClassForm .commentTitleLine {
	margin-top: 10px;
}

.courseSubmit {
	margin-top: 20px;
	background-color: white;
	border-radius: 20px;
	color: black;
	font-weight: 600;
	border: 1px solid white;
	font-size: 18px;
}

.courseNameInput {
	max-width: 75%;
	font-size: 17px;
}

.courseNumberInput {
	max-width: 10%;
	min-width: 41px;
	font-size: 17px;
}

.courseSubmit {
	margin-bottom: 0px;
}

.bottom-line {
	display: flex;
	align-items: center;
	gap: 15px;
	margin-top: 20px;
	margin-bottom: 10px;
}

@media (max-width: 600px) {
	.newClassForm {
		padding-inline: 20px;
		width: calc(100% - 40px - 2 * var(--mobile-margin-inline));
		border-radius: 35px;
		margin-bottom: 15px;
		margin-inline: var(--mobile-margin-inline);
		margin-top: 20px;
		max-width: none;
	}

	.addACourseTitle {
		margin-top: 15px;
		margin-bottom: 15px;
		font-size: 24px;
	}

	.courseDisclaimer {
		margin-right: 0px;
		margin-bottom: 15px;
		font-size: 14px;
	}

	.createCourseQuestion {
		margin-right: 0px;
		margin-bottom: 15px;
	}

	.createCourseQuestionTitle {
		margin-bottom: 5px;
	}

	.courseNameInput,
	.courseNumberInput {
		margin-bottom: 0px;
	}

	.newClassForm .MuiSelect-select {
		font-size: 14px !important;
		margin-left: 7px !important;
		margin-right: 30px !important;
	}
 
	.newClassForm .MuiPaper-root {
		max-height: 50% !important;
		margin-left: 15px !important;
		width: 275px !important;
		margin-top: 25px !important;
	}

	.addCourseDepartment {
		max-width: 30ch;
		overflow: ellipsis;
	}

	.courseSubmit {
		font-size: 15px;
		margin-top: 15px;
	}
}