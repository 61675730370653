.reviews {
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 20px;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 5px;
}

.filter1 {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
}

.filter2 {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
}

.filter-text {
	font-weight: 500;
	margin-right: 30px;
    color: black;
}

.reviewCount {
	color: black;
    margin-top: 15px;
    text-align: right !important;
    margin-bottom: 10px;
}

.show-all-reviews-div {
    margin-inline: 20px;
    margin-block: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.show-all-button {
    border: none;
    border-radius: 20px;
    padding: 7px 13px;
    font-size: 20px;
    font-weight: 500;
}

.show-all-button:hover {
    background-color: var(--white-button-hover);
    cursor: pointer;
}

.dropdown-menu {
    border: 1px solid white;
    border-radius: 50%;
    padding: 0;
}
  
.dropdown-item {
    padding: 0;
}
  
.dropdown-toggle::after {
    display: none;
    margin: 0;
}

.MuiSelect-icon {
    color: white;
    fill: white;
}

.noReviewsPage {
    display: flex;
    margin-top: 1rem;
    align-items: center;
    gap: 1rem;
}

.noReviews{
    color: white;
    font-size: 1.5em;
}

.noReviews-coursereviewsfilter {
    margin-block: 20px;
    margin-left: 30px;
}

@media screen and (max-width: 900px) {
    .filter-text {
        margin-top: 10px;
    }
}

@media screen and (max-width: 800px) {
	.reviews {
        display: flex;
        justify-content: center;
        margin-top: 0px;
        margin-left: 0;
        align-items: center;
    }

    .bottom {
        margin-left: 0;
        width: 100%;
        margin-right: 0;
    }

	.filters {
        min-width: 85%;
        align-items: center;
        margin-left: 20px;
        margin-bottom: 5px;
        font-size: 15px;
    }

    .noReviews-coursereviewsfilter {
        margin-left: 20px;
    }

    .noReviews {
        font-size: 1.2em;
    }

	.filter1 {
        flex-direction: row;
        align-items: center;
        margin-right: 20px;
        gap: 15px;
        margin-top: 10px;
    }
    
    .filter2 {
        flex-direction: row;
        align-items: center;
        margin-right: 20px;
        gap: 15px;
        margin-top: 10px;
    }

	.filter-text {
        font-weight: 500;
        margin: 0px;
    }

	.filters .MuiInputBase-root {
        margin-top: 0px !important;
        height: 30px !important;
    }

	.reviewCount {
        font-size: 13px;
        margin-top: 10px;
        margin-bottom: 0px;
    }

	.show-all-button {
        font-size: 16px;
        font-weight: 500;
    }

    .noReviewsPage {
        margin-left: 0.5rem;
        margin-top: 0rem;
    }

    .reviews .MuiSelect-select {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        /* margin-left: 10px !important; */
        font-size: 13px !important;
    }
}

@media screen and (max-width: 600px) {
    .filter-text {
        min-width: 38px;
    }
}