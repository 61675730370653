.reviewData .orange-quality,
.reviewData .lightgreen-quality,
.reviewData .yellow-quality,
.reviewData .green-quality,
.reviewData .red-quality {
    border-radius: 15px;
}

.orange-quality {
    background-color: rgba(255, 140, 0, 0.125);
}

.orange-quality.bold {
   border: 1.5px solid rgb(221, 96, 0);
}

.orange-quality-text {
    color: rgb(173, 83, 0);
}

.lightgreen-quality {
    background-color: rgba(187, 230, 118, 0.196);
}

.lightgreen-quality.bold {
    border: 1.5px solid rgb(135, 177, 0);
}

.lightgreen-quality-text {
    color: rgb(35, 70, 0);
}

.yellow-quality {
    background-color: rgba(255, 230, 0, 0.184);
}

.yellow-quality.bold {
    border: 1.5px solid rgb(218, 167, 0);
}

.yellow-quality-text {
    color: rgb(104, 83, 0);
}

.green-quality {
    background-color: rgba(0, 229, 46, 0.121);
}

.green-quality.bold {
    border: 1.5px solid rgb(0, 154, 31);
}

.green-quality-text {
    color: rgb(0, 91, 18); 
}

.red-quality {
    background-color: rgba(255, 0, 0, 0.1);
}

.red-quality.bold {
    border: 1.5px solid rgb(154, 0, 0);
}

.red-quality-text {
    color: rgb(100, 0, 0);
}

.reviewStatsText {
    padding: 10px 20px;
}
