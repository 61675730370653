.reviewPage {
	display: flex;
	justify-content: left;
}

.reviewPage form {
	color: white;
	background-image: var(--grey-background);
	padding-top: 5px;
	padding-bottom: 10px;
	padding-left: 30px;
	border-radius: 40px;
	margin-top: 2rem;
	margin-bottom: 20px;
	margin-left: 5rem;
	margin-right: 3rem;
	max-width: 1275px;
}

.questions {
	display: flex;
	flex-direction: column;
}

.topQuestions {
	display: flex;
	margin-top: 30px;
	flex-wrap: wrap;
}

.rightquestions {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	width: 100%;
}

.question {
	display: flex;
	flex-direction: column;
	margin-right: 30px;
	margin-bottom: 10px;
}

.questionTitle {
	display: flex;
	margin-bottom: 10px;
	font-weight: 700;
	margin-bottom: 10px;
}
  
.yearTaken {
	margin-bottom: 10px;
	padding: 5px 10px;
	font-size: 16px;
	background-color: black;
	border: 1px solid white;
	color: white;
	border-radius: 10px;
	height: 30px !important;
	width: 80px;
	appearance: none;
	-webkit-appearance: none;
}

.inline-link {
	display: inline-block;
	text-decoration: underline;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text {
	color: white
}

.ratingButtons {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 25px;
}

.disclaimer {
	color: var(--disclaimer-text);
	width: 85%;
}

.commentBox {
	width: 70%;
	min-height: 100px;
	padding: 8px;
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	white-space: normal;
	font-size: 16px;
	resize: none;
	border-radius: 10px;
}

.commentBox:focus {
	border: 2px solid white;
}

.bottomLine {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.commentTitleLine {
	display: flex;
	gap: 10px;
	margin-bottom: 10px;
	align-items: center;
}

.commentTitle {
	display: flex;
	font-weight: 700;
}

.buttonRow {
	display: flex;
	flex-wrap: wrap;
	padding-right: 15px; 
}

.commentLength {
	display: flex;
	font-size: 14px;
	min-width: 122px;
	justify-content: flex-end;
}

.submit {
	background-color: white;
	border-radius: 20px;
	color: black;
	font-weight: 600;
	font-size: 18px;
	padding: 5px 10px;
}

.submit:hover {
	background-color: var(--white-text-hover);
	cursor: pointer;
}

@media screen and (max-width: 1100px) {
	.reviewPage form {
		margin-inline: 2rem;
	}
}

@media (max-width: 800px) {
	.reviewPage form {
		padding-top: 5px;
		padding-bottom: 10px;
		margin-block: 20px;
		margin-inline: var(--mobile-margin-inline);
	}

	.addAReviewTitle {
		font-size: 1.5rem;
		margin-top: 15px;
		margin-bottom: 15px;
	}

	.disclaimer {
		width: 90%;
		font-size: 14px;
	}

	.topQuestions .MuiOutlinedInput-root {
		height: 35px !important;
	}

	.reviewPage .MuiInputBase-input {
		height: auto;
		color: white !important;
		/* padding-block: 0px !important; */
	}
	
	.topQuestions {
		margin-top: 20px;
	}
	
	.rightquestions {
		margin-top: 10px;
	}
	
	.question {
		display: flex;
		flex-direction: column;
		margin-right: 10px;
		margin-bottom: 15px;
	}
	
	.questionTitle {
		font-size: 15.5px;
		margin-bottom: 12px;
	}
	
	.ratingButtons {
		width: 100%;
		margin-bottom: 20px;
	}

	.questions .MuiPaper-root {
		width: fit-content !important;
		min-width: 0px !important;
	}

	.questions .MuiList-root {
		margin-right: 0px !important;
	}

	.questions .MuiMenuItem-root {
		height: 30px !important;
	}

	.comment {
		margin-top: 0px;
		margin-bottom: 15px;
		width: 90%;
	}

	.commentBox {
		width: 95%;
	}

	.submit {
		font-size: 15px;
	}

	.yearTaken {
		margin-bottom: 0px;
		padding-top: 1.5px;
		padding-bottom: 1.5px;
	}
}