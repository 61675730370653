.MuiPopover-paper {
	background-color: rgba(35, 35, 35, 0.6) !important;
	backdrop-filter: blur(10px) brightness(100%);
	border: 0.5px solid rgba(255, 255, 255, 0.3);
	color: white !important;
	border-radius: 5px !important;
}

.MuiMenuItem-root{
	font-size: 15px !important;
}

.MuiMenuItem-root:hover {
	background-color: rgba(220, 220, 220, 0.3) !important;
}

.MuiAutocomplete-option {
	background-color: white !important;
}

.MuiAutocomplete-option.Mui-focused {
	background-color: rgba(25, 118, 210, 0.08) !important;
}

.Mui-selected {
	background-color: rgba(199, 199, 199, 0.3);
}

.DepartmentPage .Mui-selected {
	color: black !important;
}

.customAutoComplete .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border: 1px solid white;
}

.MuiAutocomplete-popper {
	padding: 5px !important;
}

.MuiList-root {
	padding-block: 5px !important;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
  
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--scrollbar-color);
}

.copy-to-clipboard-icon {
	color: white;
	font-size: 20px;
}

.copy-to-clipboard-icon:hover {
	color: var(--white-button-click);
}

.check-icon {
	color: white;
	font-size: 20px;
}

.navbar-search .custom-select .MuiSvgIcon-root {
	fill: black !important;
}

.navbar-search .custom-select .MuiOutlinedInput-notchedOutline {
	border: none !important;	
}

.navbar-search .MuiSelect-select {
	padding: 0 !important;
	padding-left: 10px !important;
}

form .MuiInputBase-root {
	background-color: black !important;
	border: 1px solid white !important;
}

form .MuiInputBase-root .MuiSvgIcon-root {
	fill: white !important;
}

form .MuiInputBase-input {
	color: white !important;
}

@media screen and (max-width: 1000px) {
	.navbar-search .custom-select {
		font-size: 0.95rem !important;
		max-width: 125px !important;
		height: 35px !important;
	}

	.navbar-search .MuiSelect-select {
		padding: 0 7px !important;
	}
}

@media screen and (max-width: 600px) {
	.MuiMenuItem-root {
		min-height: 30px !important;
		font-size: 16px !important;
	}

	.MuiAutocomplete-root,
	.MuiAutocomplete-input {
		padding: 4px !important;
	}

	.MuiAutocomplete-option {
		min-height: 30px !important;
		font-size: 16px !important;
	}
}