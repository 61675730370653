.stat-line-green {
    border: 1px solid rgb(12, 124, 0);
    color: rgba(220, 255, 216, 0.865);
}

.stat-line-lightgreen {
    border: 1px solid rgb(127, 219, 115);
    color: rgba(224, 252, 181, 0.865);
}

.stat-line-yellow {
    border: 1px solid rgb(239, 223, 47);
    color: rgba(255, 248, 183, 0.865);
}

.stat-line-orange {
    border: 1px solid rgb(255, 168, 63);
    color: rgba(255, 194, 150, 0.865);
}

.stat-line-red {
    border: 1px solid rgb(239, 70, 44);
    color: rgba(255, 174, 165, 0.865);
}